import React from "react";
import { Link } from "gatsby";
import { Layout, Row, Col, BackTop, Button } from "antd";
import { Helmet } from "react-helmet";
import CommonHeader from "../../components/common/header";
import CommonFooter from "../../components/common/footer";
import UseCaseSider from "../../components/useCases/use-cases-sider";

import css from "../../styles/guide.css";

const UseCaseWorkScheduler = ({}) => (
  <div>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Online Work Scheduler - DayViewer</title>
      <meta
        name="description"
        content="Need a work scheduler - have you tried DayViewer Online Planner?. Helps you plan time and tasks in a team - making it ideal as a works or job scheduler system."
      />
    </Helmet>
    <Layout>
      <CommonHeader />
      <Layout style={{ minHeight: "100vh" }}>
        <BackTop />
        <UseCaseSider />
        <Layout className="guide-body">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h1>Flexible Easy To Use Online Work Scheduler </h1>
            <Button className="homepage-signup-btn">
              <a
                href="#"
                onClick={() =>
                  window.open("https://app.dayviewer.com/auth/signup", "_self")
                }
              >
                Sign Up
              </a>
            </Button>
          </div>
          <Row>
            <Col>
              <div>
                <video
                  preload="none"
                  controls="controls"
                  poster="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/DayViewer-FastPlanner-Teams-Image.png"
                  // muted="muted"
                  // autoplay="autoplay"
                  // loop="loop"
                  width="100%"
                  name="Online Work Scheduler"
                  src="https://res.cloudinary.com/dayviewer1/video/upload/v1669137012/DV_fast_planner_scheduling-bg-music_q7v3xb.mp4"
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <br />
              <h2>Online Work Scheduler</h2>
              <p>
                At DayViewer, we made a calendar based task system combining
                task and time management. We (and our users) have found this has
                made it a more effective tool for work scheduling.
              </p>
              <p>
                Using the DayViewer Team Room system to expedite jobs and tasks
                makes sense as everyone involved will know exactly where to be
                and what to do at any time. Laser focussed coordination is the
                aim of our system.
              </p>
              <h3>Plan schedules as needed</h3>
              <p>
                Management and delegation of tasks can be an acquired and
                difficult skill, by planning around a shared calendar system -
                your staff, contractors or other key persons (or services) will
                know exactly when you need to get things done.
              </p>
              <h3>How would DayViewer help schedule work?</h3>
              <p>
                We noticed a strong need for businesses that have several
                members in their team, were actively looking for a simple way to
                keep coordinated. We created{" "}
                <strong>DayViewer Team Rooms</strong> as a response to this
                need. Within team rooms is a team shared calendar, notes, task
                manager and contacts manager and other useful productivity
                tools. Simply connect up your team and everyone can work around
                a shared company or department calendar.
              </p>

              <h3>
                Creating a Task takes seconds - take a look at our{" "}
                <Link to="/tutorial-content/quick-start/">tutorial</Link> to see
                how.
              </h3>

              <p>
                See more details about Team Rooms in our{" "}
                <Link to={`/use-cases/team-calendar`}>
                  Team Calendar section
                </Link>
              </p>
              <p>
                Commenting on calendar entries is now also supported - with the
                ability to create a highly focussed discussion per task or other
                entry. The simple consistent interface makes it very easy for
                you to discuss a task or event.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Summary</h3>
              <p>
                We believe we have one of the simplest to use interfaces with
                the focus on clarity, flexibility, security and performance.
                Being fairly priced you can remove redundant tools from your
                productivity suite, along with a number of privacy and training
                implications the adoption of a number of tools comes with.
              </p>
              <p>
                Trial DayViewer Team Rooms free for 14 days by{" "}
                <Button className="homepage-signup-btn">
                  <a
                    href="#"
                    onClick={() =>
                      window.open(
                        "https://app.dayviewer.com/auth/signup",
                        "_self"
                      )
                    }
                  >
                    Signing Up
                  </a>
                </Button>{" "}
                <Link to={`/plans/`}>(Team Room Pricing)</Link>,
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Online Work Scheduler</h3>
              <a
                href="#"
                onClick={() =>
                  window.open("https://app.dayviewer.com/auth/signup", "_self")
                }
              >
                <img
                  src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/dv-team-cards-progress.gif"
                  alt="online work scheduler"
                  title="DayViewer Work Scheduler"
                />
              </a>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Similar Use Cases</h3>
              <Link to={`/use-cases/team-calendar`}>Team Calendar</Link>,
              <Link to={`/use-cases/office-diary`}>Office Diary</Link>
            </Col>
          </Row>
        </Layout>
      </Layout>
      <CommonFooter />
    </Layout>
  </div>
);

export default UseCaseWorkScheduler;
