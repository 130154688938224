import React from "react";
import { Link } from "gatsby";
import { Layout, Menu } from "antd";
import { BookOutlined } from "@ant-design/icons";
const { Sider } = Layout;

export default class UseCaseSider extends React.Component {
  render() {
    return (
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        <Link to={`/use-cases/`}>
          <h2 style={{ textAlign: "center", marginTop: 20 }}>
            <BookOutlined /> Use Cases
          </h2>
        </Link>
        <div>
          <Menu theme="light">
            <Menu.Item key="1">
              <Link to="/use-cases/team-calendar">Team Calendar</Link>
            </Menu.Item>
            <Menu.Item key="2">
              <Link to="/use-cases/work-scheduler">Work Scheduler</Link>
            </Menu.Item>
            <Menu.Item key="3">
              <Link to="/use-cases/office-diary">Office Diary</Link>
            </Menu.Item>
            <Menu.Item key="4">
              <Link to="/use-cases/career-planner">Career Planner</Link>
            </Menu.Item>
            <Menu.Item key="5">
              <Link to="/use-cases/travel-planner">Travel Planner</Link>
            </Menu.Item>
            <Menu.Item key="6">
              <Link to="/use-cases/content-calendar">Content Calendar</Link>
            </Menu.Item>
            <Menu.Item key="7">
              <Link to="/use-cases/task-manager">Task Management</Link>
            </Menu.Item>
            <Menu.Item key="8">
              <Link to="/use-cases/remote-working">Remote Working</Link>
            </Menu.Item>
            <Menu.Item key="9">
              <Link to="/use-cases/week-planner">Week Planner</Link>
            </Menu.Item>
          </Menu>
        </div>
      </Sider>
    );
  }
}
